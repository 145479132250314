import { defineStore } from 'pinia';
import DOMPurify from 'isomorphic-dompurify';
import type {
  Booking,
  BookingErrorType,
  BookingPeriod,
  PeriodSelectResult,
  PayloadCreateBooking,
  Booklet,
  DetailStep,
  ComplexProject,
  ProjectBuilding
} from '@/types/Booking';
import projectQuery from '@/queries/booking/project.gql';
import projectBuildingsQuery from '@/queries/booking/projectBuildingsQuery.gql';

import { BOOKING_ERROR_CODES } from '@/constants/errorCodes';

const repeatButton = {
  buttonHandler: 'repeat',
  buttonLink: '/reservations/',
  buttonText: 'Забронировать ещё раз',
  buttonTheme: 'primary'
};

const flatsButton = {
  buttonLink: `//${ process.env.TOP_HOST }/flats`,
  isExternalLink: true,
  buttonText: 'Все квартиры'
};

const reservationsButton = {
  buttonLink: '/reservations/',
  buttonText: 'Вернуться к списку броней'
};

interface ButtonType {
  buttonHandler?: string;
  buttonLink: string;
  buttonText: string;
  buttonTheme: string;
  isExternalLink?: boolean;
}

class BookingError {
  message?: string;
  reason?: string;
  errorTitle: string;
  errorSubtitle?: string;
  buttonText?: string;
  buttons: Array<ButtonType>;

  constructor (data: BookingErrorType) {
    this.message = data.response?.data?.message || data.message;
    this.reason = data.response?.data?.reason || data.reason;
    this.errorTitle = 'Ошибка';
    this.errorSubtitle = this.message || 'Произошла ошибка на стороне сервера, попробуйте еще раз.';
    this.buttons = [
      repeatButton,
      {
        ...flatsButton,
        buttonTheme: 'secondary'
      }
    ];

    const {
      OFFER_ERROR,
      PROPERTY_UNDEFINED,
      PROPERTY_UNAVAILABLE,
      PROPERTY_MISSING,
      UNFINISHED_EXISTS,
      GET_PERIODS_ERROR,
      SELECT_PERIODS_ERROR,
      NOT_FOUND,
      TIME_OUT_REPEAT,
      TIME_OUT,
      PAYMENT_FAILED,
      UNKNOWN_REASON
    } = BOOKING_ERROR_CODES;

    switch (this.reason) {
      case OFFER_ERROR:
        this.errorTitle = 'Ошибка';
        this.errorSubtitle = 'Не удалось получить договор оферту.';
        this.buttons = [repeatButton];
        break;
      case PROPERTY_UNDEFINED:
        this.errorTitle = 'Объект не найден';
        this.errorSubtitle = 'Не выбран объект бронирования.';
        this.buttons = [
          {
            ...flatsButton,
            buttonTheme: 'primary'

          },
          {
            buttonLink: '/favorites',
            buttonText: 'В избранное',
            buttonTheme: 'secondary'
          }
        ];
        break;
      case PROPERTY_UNAVAILABLE:
        this.errorTitle = 'Объект недоступен';
        this.errorSubtitle = 'Извините, но данный лот уже забронировали. Вы можете посмотреть другие лоты в вашем избранном. Или выбрать другую квартиру.';
        this.buttons = [
          {
            buttonLink: '/favorites',
            buttonText: 'Вернуться в избранное',
            buttonTheme: 'primary'
          }, {
            ...flatsButton,
            buttonTheme: 'secondary'
          }
        ];
        break;
      case PROPERTY_MISSING:
        this.errorTitle = 'Объект не найден';
        this.errorSubtitle = 'Произошла ошибка на стороне сервера, попробуйте еще раз.';
        this.buttons = [
          repeatButton,
          {
            ...flatsButton,
            buttonTheme: 'secondary'
          }
        ];
        break;
      case UNFINISHED_EXISTS:
        this.errorTitle = 'Вы не завершили бронирование';
        this.errorSubtitle = 'Завершите или удалите свою предыдущую бронь, чтобы приступить к новому бронированию.';
        this.buttons = [
          {
            ...reservationsButton,
            buttonTheme: 'primary'
          }
        ];
        break;
      case GET_PERIODS_ERROR:
        this.errorTitle = 'Ошибка';
        this.errorSubtitle = 'Не удалось получить периоды бронирования.';
        this.buttons = [
          {
            ...reservationsButton,
            buttonTheme: 'primary'
          }
        ];
        break;
      case SELECT_PERIODS_ERROR:
        this.errorTitle = 'Ошибка';
        this.errorSubtitle = 'Не удалось установить период бронирования.';
        this.buttons = [
          {
            ...reservationsButton,
            buttonTheme: 'primary'
          }
        ];
        break;
      case NOT_FOUND:
        this.errorTitle = 'Ошибка';
        this.errorSubtitle = 'Бронирование не найдено или было завершено, попробуйте забронировать ещё раз.';
        this.buttons = [repeatButton];
        break;
      case TIME_OUT_REPEAT:
        this.errorTitle = 'Время на оплату истекло';
        this.errorSubtitle = 'Но мы сохранили все ваши данные. Попробуйте забронировать лот еще раз.';
        this.buttonText = 'Забронировать ещё раз';
        this.buttons = [
          {
            ...reservationsButton,
            buttonTheme: 'secondary'
          },
          {
            ...repeatButton,
            buttonLink: '',
            isExternalLink: true
          }
        ];
        break;
      case TIME_OUT:
        this.errorTitle = 'Время на оплату истекло';
        this.errorSubtitle = 'Свяжитесь с вашим менеджером или брокером для продления сделки';
        this.buttons = [
          {
            ...reservationsButton,
            buttonTheme: 'secondary'
          }
        ];
        break;
      case PAYMENT_FAILED:
        this.errorTitle = 'Ошибка эквайринга';
        this.errorSubtitle = 'Произошла ошибка на стороне банка, попробуйте еще раз.';
        this.buttons = [repeatButton];
        break;
      case UNKNOWN_REASON:
        this.errorTitle = 'Ошибка';
        this.errorSubtitle = this.message;
        this.buttons = [repeatButton];
    }
  }
}

const endpoints = {
  createBooking: '/api/booking/create_booking',
  getPeriods: '/api/booking/types',
  getBookings: '/api/booking',
  repeatBooking: '/api/booking/repeat',
  offer: (bookingId: number | string): string => `/api/booking/documents/${ bookingId }`,
  selectPeriods: (bookingId: number | string): string => `/api/booking/payment_conditions/${ bookingId }`,
  acceptOffer: (bookingId: number | string): string => `/api/booking/accept/${ bookingId }`,
  getData: (bookingId: number | string): string => `/api/booking/${ bookingId }`,
  fillPersonal: (bookingId: number | string): string => `/api/v2/booking/fill/${ bookingId }`,
  checkParams: (bookingId: number | string): string => `/api/booking/check/${ bookingId }`,
  getSberbankLink: (bookingId: number | string): string => `/api/booking/sberbank_link/${ bookingId }`,
  actualStages: (bookingId: number | string): string => `/api/booking/${ bookingId }/actual_stages`
};

const bookingMock = {
  id: null,
  expires: null,
  created: null,
  property: undefined,
  building: undefined,
  project: undefined,
  floor: undefined,
  payment_url: undefined,
  payment_amount: null,
  contract_accepted: false,
  personal_filled: false,
  params_checked: false,
  price_payed: false,
  crossed_price: null,
  final_additional_options: null,
  final_discount: null,
  client_group_statuses: []
};

interface State {
  bookingId: number | null;
  bookingData: Booking;
  bookingType: string | null;
  offerHTML: string | null;
  bookingPeriods: Array<BookingPeriod> | null;
  error: BookingErrorType;
  booklets: Array<Booklet>;
  reservationSteps: Array<DetailStep>;
  project: ComplexProject | null;
  allBuildings: Array<ProjectBuilding>;
}

export const useBookingStore = defineStore('booking', {
  state: (): State => ({
    bookingId: null,
    bookingData: bookingMock,
    bookingType: null,
    offerHTML: null,
    bookingPeriods: null,
    error: {},
    booklets: [],
    reservationSteps: [],
    project: null,
    allBuildings: []
  }),

  actions: {
    handleBookingError (payload: BookingErrorType): void {
      console.error('🚀 ~ file: booking.ts ~ handleBookingError ~ payload', payload);
      this.error = new BookingError(payload);
      this.$router.push('/booking/error');
    },

    setBookingType (payload: string): void {
      this.bookingType = payload;
    },

    async repeatBooking (): Promise<void> {
      try {
        const url = endpoints.repeatBooking;
        const { data } = await this.$axios.post<Booking>(url, {
          booking_id: this.bookingData?.id ?? this.bookingId
        });

        if (!data?.id) {
          this.handleBookingError({
            reason: BOOKING_ERROR_CODES.NOT_FOUND
          });

          return;
        }

        this.bookingData = {
          ...this.bookingData,
          ...data
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ repeatBooking ~ e', error);
        throw error;
      }
    },

    async getBooking (id: number): Promise<void> {
      try {
        console.info('FETCHING BOOKING', id);
        this.bookingId = id;
        const url = endpoints.getData(id);
        const { data } = await this.$axios.get<Booking>(url);

        if (!data) {
          this.handleBookingError({
            reason: BOOKING_ERROR_CODES.NOT_FOUND
          });

          return;
        }

        this.bookingData = {
          ...this.bookingData,
          ...data
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ getBooking ~ e', error);
        this.handleBookingError(error as BookingErrorType);
        throw error;
      }
    },

    async createBooking (payload: PayloadCreateBooking): Promise<void> {
      try {
        const url = endpoints.createBooking;
        const { data } = await this.$axios.post<Booking>(url, payload);
        this.bookingData = {
          ...this.bookingData,
          ...data
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ createBooking ~ e', error);
        this.handleBookingError(error as BookingErrorType);
      }
    },

    async getOfferHTML (payload: { bookingId: number }): Promise<void> {
      const bookingId = payload?.bookingId;

      if (!bookingId) {
        return;
      }

      try {
        const url = endpoints.offer(bookingId);
        const { data } = await this.$axios.get<{ text: string }>(url);

        this.offerHTML = DOMPurify.sanitize(data.text, { USE_PROFILES: { html: true } });
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ getOfferHTML ~ e', error);
        this.handleBookingError({
          reason: BOOKING_ERROR_CODES.OFFER_ERROR
        });
      }
    },

    async acceptOffer (payload: { bookingId: number }): Promise<void> {
      const bookingId = payload?.bookingId;

      if (!bookingId) {
        return;
      }

      try {
        const url = endpoints.acceptOffer(bookingId);
        const { data } = await this.$axios.patch<Booking>(url);

        this.bookingData = {
          ...this.bookingData,
          ...data
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ acceptOffer ~ e', error);
        this.handleBookingError(error as BookingErrorType);
      }
    },

    async getPeriods (bookingId: number): Promise<void> {
      try {
        const url = endpoints.getPeriods;
        const { data: periods } = await this.$axios.get<Array<BookingPeriod>>(url, {
          params: {
            bookingId
          }
        });

        if (periods?.length > 0) {
          this.bookingPeriods = periods;
        } else {
          this.handleBookingError({
            reason: BOOKING_ERROR_CODES.GET_PERIODS_ERROR
          });

          return;
        }
      } catch (error) {
        this.$sentry.captureException(error);
        this.handleBookingError({
          reason: BOOKING_ERROR_CODES.GET_PERIODS_ERROR
        });
      }
    },

    async periodSelect (payload: { bookingId: number; period: number }): Promise<void> {
      try {
        const url = endpoints.selectPeriods(payload.bookingId);
        const { data } = await this.$axios.patch<PeriodSelectResult>(url, {
          bookingType: payload.period
        });

        if (!data?.conditionChosen) {
          this.handleBookingError({
            reason: BOOKING_ERROR_CODES.SELECT_PERIODS_ERROR
          });

          return;
        }
      } catch (error) {
        this.$sentry.captureException(error);
        this.handleBookingError({
          reason: BOOKING_ERROR_CODES.SELECT_PERIODS_ERROR
        });
      }
    },

    async fillPersonal (): Promise<void> {
      try {
        if (this.bookingData.personal_filled) {
          console.warn('PERSONAL ALREADY FILLED');

          return;
        }

        if (!this.bookingData.id) {
          return;
        }

        const url = endpoints.fillPersonal(this.bookingData.id);
        const { data } = await this.$axios.patch<Booking>(url, {
          personal_filled: true,
          email_force: false
        });

        if (!data?.id) {
          return;
        }

        this.bookingData.personal_filled = true;
        this.bookingData = {
          ...this.bookingData,
          ...data
        };
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ fillPersonal ~ e', error);
        this.handleBookingError(error as BookingErrorType);
      }
    },

    async checkParams (sberbankPageView: 'MOBILE' | 'DESKTOP'): Promise<void> {
      try {
        if (!this.bookingData.id) {
          return;
        }

        const url = endpoints.checkParams(this.bookingData.id);
        const { data: sberBankLinkRes } = await this.$axios.patch<Booking>(url, {
          params_checked: true,
          payment_page_view: sberbankPageView
        });

        this.bookingData.params_checked = true;
        this.bookingData.payment_url = sberBankLinkRes?.payment_url;
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ checkParams, error', error);
        this.handleBookingError(error as BookingErrorType);
      }
    },

    async getBankLink (sberbankPageView: 'MOBILE' | 'DESKTOP'): Promise<void> {
      try {
        if (!this.bookingData.id) {
          return;
        }

        const url = endpoints.getSberbankLink(this.bookingData.id);
        // eslint-disable-next-line camelcase
        const { data } = await this.$axios.post<{ payment_url: string }>(url, {
          payment_page_view: sberbankPageView
        });

        this.bookingData.payment_url = data?.payment_url;
        this.bookingData.params_checked = true;
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ getBankLink, error', error);
        this.handleBookingError(error as BookingErrorType);
      }
    },

    async getProject (slug: string): Promise<void> {
      if (!slug) {
        return;
      }

      try {
        const {
          body: query
        } = projectQuery.loc.source;

        const {
          data: {
            data
          }
        } = await this.$portal.post('', {
          query,
          variables: {
            slug
          }
        });

        if (!data && !data?.result) {
          return;
        }

        this.project = data?.result;

        if (this.project) {
          this.fillBooklets(this.project);
        }
      } catch (error) {
        console.log('🚀 ~ file: booking.ts ~ getProject ~ error', error);
        this.$sentry.captureException(error);
      }
    },

    fillBooklets (project: ComplexProject): void {
      this.booklets = [];

      if (project?.presentation) {
        this.booklets.push({
          title: 'Буклет проекта',
          link: project.presentation
        });
      }

      if (project?.bookletLink && project?.bookletTitle) {
        this.booklets.push({
          title: project.bookletTitle,
          link: project.bookletLink
        });
      }
    },

    async getReservationSteps (bookingId: number): Promise<void> {
      if (!bookingId) {
        return;
      }

      try {
        const url = endpoints.actualStages(bookingId);
        const { data } = await this.$axios.get<Array<DetailStep>>(url);

        this.reservationSteps = data;
      } catch (error) {
        this.$sentry.captureException(error);
        console.error('🚀 ~ file: booking.ts ~ getReservationSteps ~ e', error);
      }
    },

    async getAllBuildings (projectId: string): Promise<void> {
      if (!projectId) {
        return;
      }

      try {
        const {
          body: query
        } = projectBuildingsQuery.loc.source;

        const {
          data: {
            data
          }
        } = await this.$portal.post('', {
          query,
          variables: {
            projectId,
            kinds: ['RESIDENTIAL']
          }
        });

        if (!data && !data?.result) {
          return;
        }

        this.allBuildings = data.result.edges
          ?.map((item: { node: Node }) => item.node);
      } catch (error) {
        console.log('🚀 ~ file: booking.ts ~ getAllBuildings ~ error', error);
        this.$sentry.captureException(error);
      }
    }
  }
});
