import mime from 'mime-types';

export const nameValidator = (value: string): boolean => {
  const nameRegExp = /^(?!.*([-–— ])\1)[а-яё ]+([-–— ][а-яё]+)*$/ui;

  if (value?.length) {
    return nameRegExp.test(value);
  }

  return true;
};

export const phoneValidator = (value: string): boolean => {
  return Boolean(value && !(value.replace(/\D/g, '').length < 11));
};

export const emailValidator = (value: string): boolean => {
  const emailRegExp = /^[\S]+@[\S]+\.[\S]+$/i;

  return emailRegExp.test(value);
};

const normalizeDate = (value: string): Date => {
  try {
    const regex = /(\d{2}).(\d{2}).(\d{4})/;
    const dateString = value.replace(regex, '$3-$2-$1');
    // код для обработки дат високосного года
    const ISODateString = new Date(dateString)
      .toISOString()
      .slice(0, 10);

    if (dateString === ISODateString) {
      return new Date(dateString);
    }
  } catch {}

  return new Date('');
};

export const birthdateValidator = (value: string): boolean => {
  if (value.length !== 10) {
    return false;
  }

  const date = normalizeDate(value);
  const minDate = new Date('1900-01-01');
  const maxDate = new Date();

  maxDate.setHours(23, 59, 59, 999);

  return minDate < date && date < maxDate;
};

export const birthdateIsOlderThanValidator = (age: number) => (value: string): boolean => {
  if (value.length !== 10) {
    return false;
  }

  const date = normalizeDate(value);
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const minDate = new Date(today.setFullYear(today.getFullYear() - age));

  return date <= minDate;
};

export const fileExtension = (allowedExtensions: Array<string>) => (value: FileList | null): boolean => {
  if (!value) {
    return true;
  }

  return Array.from(value).every(({ name }) => {
    const fileType = mime.lookup(name);

    return fileType ? allowedExtensions.includes(fileType) : false;
  });
};

export const fileMaxSize = (maxSizeInMB: number) => (value: FileList | null): boolean => {
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  if (!value) {
    return true;
  }

  return Array.from(value).every((file) => {
    if (file?.size) {
      return file.size <= maxSizeInBytes;
    }

    return true;
  });
};

export const fileMaxCount = (maxCount: number) => (value: FileList | null): boolean => {
  if (!value) {
    return true;
  }

  return value.length <= maxCount;
};
