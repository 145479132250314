import { render, staticRenderFns } from "./TheBurgerMenu.vue?vue&type=template&id=52719e80"
import script from "./TheBurgerMenu.vue?vue&type=script&lang=js"
export * from "./TheBurgerMenu.vue?vue&type=script&lang=js"
import style0 from "./TheBurgerMenu.vue?vue&type=style&index=0&id=52719e80&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderNotificationIcon: require('/app/src/components/common/HeaderNotificationIcon.vue').default,TheMenuButton: require('/app/src/components/layout/TheMenuButton.vue').default,HeaderSelectCity: require('/app/src/components/common/header-select-city/index.vue').default,RemoteSVG: require('/app/common/components/icons/RemoteSVG.vue').default,AvatarButton: require('/app/src/components/common/navigation/AvatarButton.vue').default,VNavigationDrawer: require('/app/src/components/ui/VNavigationDrawer.vue').default})
